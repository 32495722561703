import React from 'react';
import _ from 'lodash';

import { HashRouter, Switch, Route } from 'react-router-dom';

import type Prefecture from '@this/domain/prefecture';
import type TripRuleError from '@this/domain/trip/trip_rule_error';
import type { SearchType } from '@this/domain/search_query';
import type TripRuleDistanceTimeInfo from '@this/domain/trip/trip_rule_distance_time_info';
import type ExicCard from '@this/domain/exic_card';
import type Department from '../../../../domain/department/department';
import type OrganizationBase from '../../../../domain/organization_base/organization_base';
import type ReserveInfo from '../../../../domain/reserve_info';
import type { TripDuplicationInterface } from '../reserve_confirm';

import InputCustomerInformation from './input_customer_information/input_customer_information';
import ConfirmCustomerInformation from './confirm_customer_information/confirm_customer_information';

type Props = {
  isCustomerInfoRequired: () => boolean;
  selectPageUrl?: string | undefined;
  reserveInfo: ReserveInfo;
  // projects;
  organizationBases: OrganizationBase[] | undefined;
  exicCards: ExicCard[];
  departments: Department[] | undefined;
  useBulkTicket: boolean | undefined;
  useKyuusyuuTicket: boolean | undefined;
  showEx: boolean | undefined;
  serviceId: number;
  termsAgree: boolean;
  handleValidationSuccess: () => void;
  // approvers;
  // shinkansenAddress;

  handleBackToSelectClick: () => void;
  handleBackToStep1Click: (e?: React.MouseEvent) => void;
  handleSaveDraft?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handlePaymentSubmit: () => void;
  handleAgreementTermsCheck: () => void;
  validateInputSimpleRequest?: () => void;

  paymentLoading: boolean;
  paymentFailed: boolean;
  paymentErrors: string;
  // disconnected;
  projectShareAvailability: boolean;
  prefecture: Prefecture[];
  isOnTripsConfirm?: boolean;
  tripRuleErrors: TripRuleError[];
  fromTripForm: boolean;
  tripDuplications: TripDuplicationInterface[];
  tripDistanceAndTimeInfo: TripRuleDistanceTimeInfo[];
  queries: { search_type: SearchType };
  isSimpleRequest?: boolean;
  errorsInputCustomer?: { [key: string]: string | undefined };
};

const ReserveConfirmRoutingsTemplate: React.SFC<Props> = ({
  isCustomerInfoRequired,
  selectPageUrl,
  reserveInfo,
  // projects,
  organizationBases,
  exicCards,
  departments,
  useBulkTicket,
  useKyuusyuuTicket,
  showEx,
  serviceId,
  termsAgree,
  handleValidationSuccess,
  // approvers,
  // shinkansenAddress,

  handleBackToSelectClick,
  handleBackToStep1Click,
  handleSaveDraft,
  handlePaymentSubmit,
  handleAgreementTermsCheck,

  paymentLoading,
  paymentFailed,
  paymentErrors,
  isSimpleRequest,
  errorsInputCustomer,
  // disconnected
  projectShareAvailability,
  prefecture,
  isOnTripsConfirm,
  tripRuleErrors,
  fromTripForm,
  tripDuplications,
  tripDistanceAndTimeInfo,
  queries
}) => (
  <HashRouter hashType="noslash">
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <InputCustomerInformation
            isCustomerInfoRequired={isCustomerInfoRequired}
            selectPageUrl={selectPageUrl}
            reserveInfo={reserveInfo}
            applicant={reserveInfo.getApplicant()}
            reservingTrip={reserveInfo.reservingTrip}
            // projects={projects}
            organizationBases={organizationBases}
            departments={departments}
            useBulkTicket={useBulkTicket}
            useKyuusyuuTicket={useKyuusyuuTicket}
            showEx={showEx}
            serviceId={serviceId}
            onSuccess={handleValidationSuccess}
            errorsInputCustomer={errorsInputCustomer}
            // approvers={approvers}
            // shinkansenAddress={shinkansenAddress}
            handleBackToSelectClick={handleBackToSelectClick}
            projectShareAvailability={projectShareAvailability}
            prefecture={prefecture}
            isOnTripsConfirm={isOnTripsConfirm || false}
            tripRuleErrors={tripRuleErrors}
            fromTripForm={fromTripForm}
            tripDuplications={tripDuplications}
            tripDistanceAndTimeInfo={tripDistanceAndTimeInfo}
            handleSaveDraft={handleSaveDraft}
            queries={queries}
            exicCards={exicCards}
            isSimpleRequest={isSimpleRequest}
            paymentLoading={paymentLoading}
          />
        )}
      />
      <Route
        exact
        path="/step2"
        render={() => (
          <ConfirmCustomerInformation
            isCustomerInfoRequired={isCustomerInfoRequired}
            reserveInfo={reserveInfo}
            reservingTrip={reserveInfo.reservingTrip}
            handleBackToStep1Click={handleBackToStep1Click}
            handleSaveDraft={handleSaveDraft}
            handlePaymentSubmit={handlePaymentSubmit}
            handleAgreementTermsCheck={handleAgreementTermsCheck}
            termsAgree={termsAgree}
            // state ?
            paymentLoading={paymentLoading}
            paymentFailed={paymentFailed}
            paymentErrors={paymentErrors}
            // disconnected={disconnected} // 渡らない
            prefecture={prefecture}
            exicCards={exicCards}
            tripDuplications={tripDuplications}
          />
        )}
      />
    </Switch>
  </HashRouter>
);

export default ReserveConfirmRoutingsTemplate;
