import React, { useEffect } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import ContentBody from '@this/components/shared/atoms/content_body';

import { AirRoutePolicies } from '@this/components/reserve_trip/reserve_confirm/air_route_policies';
import type Prefecture from '@this/domain/prefecture';
import type TripRuleError from '@this/domain/trip/trip_rule_error';
import type { SearchType } from '@this/domain/search_query';
import { useHistory } from 'react-router-dom';
import type TripRuleDistanceTimeInfo from '@this/domain/trip/trip_rule_distance_time_info';
import type User from '@this/domain/user/user';
import type ExicCard from '@this/domain/exic_card';
import type ReserveInfo from '../../../domain/reserve_info';

import type Department from '../../../domain/department/department';
import type OrganizationBase from '../../../domain/organization_base/organization_base';

import type { TripDuplicationInterface } from './reserve_confirm';

import SimpleLoading from '../../shared/simple_loading/simple_loading';
import ReserveConfirmOutlineTemplate from './outline/outline.template';
import ReserveConfirmRoutingsTemplate from './routings/routings.template';

import StepBar from '../../step_bar';

export type Props = {
  serviceId: number;
  loading: boolean;
  responseError: string | undefined;
  returning: boolean;
  itemsBlankError: boolean;
  reserveInfo: ReserveInfo | null;
  showError: boolean;
  steps: string[];
  stepIndex: number;
  peoplenum: number;
  roomnum: number | undefined;
  queries: { stay_days: number; search_type: SearchType } | null;
  termsAgree: boolean;
  // shinkansenTooLate: boolean;

  isCustomerInfoRequired: () => boolean;
  selectPageUrl: string | undefined;
  // projects,
  departments: Department[] | undefined;
  organizationBases: OrganizationBase[] | undefined;
  exicCards: ExicCard[];
  useBulkTicket: boolean | undefined;
  useKyuusyuuTicket: boolean | undefined;
  showEx: boolean | undefined;
  handleValidationSuccess: () => void;
  // approvers,

  // shinkansenAddress;

  paymentLoading: boolean;
  paymentFailed: boolean;
  paymentErrors: string;
  // disconnected;

  handleBackToSelectClick: () => void;
  handleBackToStep1Click: () => void;
  handlePaymentSubmit: () => void;
  handleAgreementTermsCheck: () => void;

  // signed_in: boolean;
  projectShareAvailability: boolean;
  prefecture: Prefecture[];
  tripRuleErrors: TripRuleError[];
  tripDuplications: TripDuplicationInterface[];
  tripDistanceAndTimeInfo: TripRuleDistanceTimeInfo[];
  userInfo: User;
};

const ReserveConfirmPcTemplate = ({
  userInfo,
  serviceId,
  loading,
  responseError,
  returning,
  itemsBlankError,
  reserveInfo,
  showError,
  steps,
  stepIndex,
  peoplenum,
  roomnum,
  queries,
  termsAgree,
  // shinkansenTooLate,

  isCustomerInfoRequired,
  selectPageUrl,
  // projects,
  organizationBases,
  exicCards,
  departments,
  useBulkTicket,
  useKyuusyuuTicket,
  showEx,
  handleValidationSuccess,
  // approvers,

  handleBackToSelectClick,
  handleBackToStep1Click,
  handlePaymentSubmit,
  handleAgreementTermsCheck,

  // shinkansenAddress,
  paymentLoading,
  paymentFailed,
  paymentErrors,
  projectShareAvailability,
  prefecture,
  tripRuleErrors,
  tripDuplications,
  tripDistanceAndTimeInfo
}: // disconnected,

// signed_in
Props) => {
  const history = useHistory();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    itemsBlankError &&
      setTimeout(() => {
        history.push('/dashboard');
      }, 5 * 1000);
  }, [itemsBlankError]);

  return (
    <Confirm style={loading ? { height: '100vh' } : undefined}>
      <ContentWrapper>
        <Body>
          {loading ? (
            <SimpleLoading />
          ) : responseError ? (
            <ReturningError>{responseError}</ReturningError>
          ) : returning ? (
            <>
              <ReturningError>
                この手配依頼はすでに受け付けられている可能性があります。
                <br />
                <a href="/trips">こちら</a>
                から依頼済みの旅程をご確認ください。
                <br />
                該当するご旅程が確認できない場合は、もう一度ご依頼ください。
              </ReturningError>
              <BackTop>
                <a href="/">トップへ戻る</a>
              </BackTop>
            </>
          ) : itemsBlankError ? (
            <>
              <ReturningError>
                検索条件に該当する商品がありません。検索条件を変更して再度検索してください。
                <br />
                5秒後に検索画面へ遷移します。
              </ReturningError>
              <BackTop>
                <a href="/">トップへ戻る</a>
              </BackTop>
            </>
          ) : !reserveInfo || !reserveInfo.reservingTrip || !queries ? (
            <ShowError>
              通信環境が不安定です。
              <br />
              時間をおいてもう一度お試しください。
            </ShowError>
          ) : showError ? (
            <ShowError>
              通信環境が不安定です。
              <br />
              時間をおいてもう一度お試しください。
            </ShowError>
          ) : (
            <>
              <ConfirmWrapHeader>
                <StepBar steps={steps} index={stepIndex} />
              </ConfirmWrapHeader>
              <ConfirmWrap>
                <ConfirmWrapContent>
                  <ReserveConfirmRoutingsTemplate
                    serviceId={serviceId}
                    isCustomerInfoRequired={isCustomerInfoRequired}
                    selectPageUrl={selectPageUrl}
                    reserveInfo={reserveInfo}
                    termsAgree={termsAgree}
                    // projects={projects}
                    organizationBases={organizationBases}
                    exicCards={exicCards}
                    departments={departments}
                    useBulkTicket={useBulkTicket}
                    useKyuusyuuTicket={useKyuusyuuTicket}
                    showEx={showEx}
                    handleValidationSuccess={handleValidationSuccess}
                    // approvers={approvers}
                    // shinkansenAddress={shinkansenAddress}
                    paymentLoading={paymentLoading}
                    paymentFailed={paymentFailed}
                    paymentErrors={paymentErrors}
                    // disconnected={disconnected}
                    handleBackToSelectClick={handleBackToSelectClick}
                    handleBackToStep1Click={handleBackToStep1Click}
                    handlePaymentSubmit={handlePaymentSubmit}
                    handleAgreementTermsCheck={handleAgreementTermsCheck}
                    projectShareAvailability={projectShareAvailability}
                    prefecture={prefecture}
                    tripRuleErrors={tripRuleErrors}
                    tripDuplications={tripDuplications}
                    tripDistanceAndTimeInfo={tripDistanceAndTimeInfo}
                    fromTripForm={false}
                    queries={queries}
                  />
                </ConfirmWrapContent>
                <RightNav>
                  <Card>
                    <ReserveConfirmOutlineTemplate
                      userInfo={userInfo}
                      reserveInfo={reserveInfo}
                      peoplenum={peoplenum}
                      roomnum={roomnum}
                      // queries={queries}
                      // shinkansenTooLate={shinkansenTooLate}
                    />
                  </Card>
                  {reserveInfo.reservingTrip.airRouteDetail && (
                    <Card>
                      <AirRoutePolicies airRoutePolicies={reserveInfo.reservingTrip.airRouteDetail} />
                    </Card>
                  )}
                </RightNav>
              </ConfirmWrap>
            </>
          )}
        </Body>
      </ContentWrapper>
    </Confirm>
  );
};

const baseMargin = '20px';
const pcWidth = '1150px';
const pcBaseMargin = '20px';
const rightNaviWidth = '400px';

const Confirm = styled.div`
  min-height: 100vh;
`;

const ReturningError = styled.div`
  padding: ${baseMargin};
  color: ${props => props.theme.redColor};
`;

const ShowError = styled.div`
  padding: ${baseMargin};
  color: ${props => props.theme.redColor};
`;

const BackTop = styled.div`
  margin-top: ${baseMargin};
  text-align: center;
  text-decoration: underline;
`;

const ContentWrapper = styled.div`
  flex-grow: 9999;
  display: flex;
  flex-direction: column;
  min-height: 0%;
  width: 100%;
  background: ${props => props.theme.wrapperBgColor};
`;

const Body = styled(ContentBody)`
  max-width: ${pcWidth};
  padding: ${pcBaseMargin};
  background: none;
`;

export const ConfirmWrapHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const ConfirmWrap = styled.div`
  display: flex;
`;

const ConfirmWrapContent = styled.div`
  width: calc(100% - ${rightNaviWidth} - 20px);
  background-color: white;
  padding: 10px 20px;
`;

const RightNav = styled.div`
  width: ${rightNaviWidth};
  margin-left: 20px;
`;

const Card = styled.div`
  padding: 10px 20px;
  font-size: 13px;
  min-height: 300px;
  background-color: white;

  & + & {
    margin-top: 20px;
  }
`;

export default ReserveConfirmPcTemplate;
