import { observable } from 'mobx';

export interface ExicCardArgs {
  id: number;
  organization_id: number;
  exic_number: string;
  password: string;
  users?: User[];
}

export interface User {
  name: string;
  email: string;
}

export class ExicCard {
  @observable id: number;

  @observable organizationId: number;

  @observable exicNumber: string;

  @observable password: string;

  @observable users: User[];

  constructor(args: Partial<ExicCardArgs> = {}) {
    this.id = args.id || 0;
    this.organizationId = args.organization_id || 0;
    this.exicNumber = args.exic_number || '';
    this.password = args.password || '';
    this.users = args.users || [];
  }

  submitParams() {
    return {
      id: this.id,
      organization_id: this.organizationId,
      exic_number: this.exicNumber,
      password: this.password
    };
  }

  setExicNumber(data: string) {
    this.exicNumber = data;
    app.render();
  }

  setEPassword(data: string) {
    this.password = data;
    app.render();
  }
}

export default ExicCard;
