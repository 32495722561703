import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import { AirRoutePolicies } from '@this/components/reserve_trip/reserve_confirm/air_route_policies';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

import ReserveConfirmOutlineTemplate from './outline/outline.template';
import ReserveConfirmRoutingsTemplate from './routings/routings.template';

import type { Props } from './reserve_confirm_pc.template';

import StepBar from '../../step_bar';

const ReserveConfirmSpTemplate: React.SFC<Props> = ({
  userInfo,
  serviceId,
  loading,
  responseError,
  returning,
  itemsBlankError,
  reserveInfo,
  showError,
  steps,
  stepIndex,
  peoplenum,
  roomnum,
  queries,
  termsAgree,
  // shinkansenTooLate,

  isCustomerInfoRequired,
  selectPageUrl,
  // projects,
  organizationBases,
  exicCards,
  departments,
  useBulkTicket,
  useKyuusyuuTicket,
  showEx,
  handleValidationSuccess,
  // approvers,

  handleBackToSelectClick,
  handleBackToStep1Click,
  handlePaymentSubmit,
  handleAgreementTermsCheck,

  // shinkansenAddress,
  paymentLoading,
  paymentFailed,
  paymentErrors,
  // disconnected,

  // signed_in
  projectShareAvailability,
  prefecture,
  tripRuleErrors,
  tripDuplications,
  tripDistanceAndTimeInfo
}) => (
  <div className="reserve_confirm" style={loading ? { height: '100vh' } : undefined}>
    <div className="content-wrapper">
      <div className="content-body">
        {loading ? (
          <SimpleLoading />
        ) : responseError ? (
          <ReturningError>{responseError}</ReturningError>
        ) : returning ? (
          <>
            <ReturningError>
              この手配依頼はすでに受け付けられている可能性があります。
              <br />
              <a href="/trips">こちら</a>
              から依頼済みの旅程をご確認ください。
              <br />
              該当するご旅程が確認できない場合は、もう一度ご依頼ください。
            </ReturningError>
            <BackTop>
              <a href="/">トップへ戻る</a>
            </BackTop>
          </>
        ) : itemsBlankError ? (
          <>
            <ReturningError>
              検索条件に該当する商品がありません。検索条件を変更して再度検索してください。
              <br />
              5秒後に検索画面へ遷移します。
            </ReturningError>
            <BackTop>
              <a href="/">トップへ戻る</a>
            </BackTop>
          </>
        ) : !reserveInfo || !reserveInfo.reservingTrip || !queries ? (
          <ShowError>
            通信環境が不安定です。
            <br />
            時間をおいてもう一度お試しください。
          </ShowError>
        ) : showError ? (
          <ShowError>
            通信環境が不安定です。
            <br />
            時間をおいてもう一度お試しください。
          </ShowError>
        ) : (
          <>
            <WrapHeader>
              <StepBar steps={steps} index={stepIndex} type="thin" />
            </WrapHeader>
            <OutlineWrapper>
              <ReserveConfirmOutlineTemplate
                userInfo={userInfo}
                reserveInfo={reserveInfo}
                peoplenum={peoplenum}
                roomnum={roomnum}
                // queries={queries}
                // shinkansenTooLate={shinkansenTooLate}
              />
            </OutlineWrapper>
            {reserveInfo.reservingTrip.airRouteDetail && (
              <OutlineWrapper>
                <AirRoutePolicies airRoutePolicies={reserveInfo.reservingTrip.airRouteDetail} />
              </OutlineWrapper>
            )}
            <ReserveConfirmRoutingsTemplate
              serviceId={serviceId}
              isCustomerInfoRequired={isCustomerInfoRequired}
              selectPageUrl={selectPageUrl}
              reserveInfo={reserveInfo}
              termsAgree={termsAgree}
              // projects={projects}
              organizationBases={organizationBases}
              departments={departments}
              useBulkTicket={useBulkTicket}
              useKyuusyuuTicket={useKyuusyuuTicket}
              showEx={showEx}
              handleValidationSuccess={handleValidationSuccess}
              // approvers={approvers}
              // shinkansenAddress={shinkansenAddress}
              paymentLoading={paymentLoading}
              paymentFailed={paymentFailed}
              paymentErrors={paymentErrors}
              // disconnected={disconnected}
              handleBackToSelectClick={handleBackToSelectClick}
              handleBackToStep1Click={handleBackToStep1Click}
              handlePaymentSubmit={handlePaymentSubmit}
              handleAgreementTermsCheck={handleAgreementTermsCheck}
              projectShareAvailability={projectShareAvailability}
              prefecture={prefecture}
              tripRuleErrors={tripRuleErrors}
              tripDuplications={tripDuplications}
              tripDistanceAndTimeInfo={tripDistanceAndTimeInfo}
              fromTripForm={false}
              exicCards={exicCards}
              queries={queries}
            />
          </>
        )}
      </div>
    </div>
  </div>
);

const baseMargin = '20px';

const ReturningError = styled.div`
  padding: ${baseMargin};
  color: ${props => props.theme.redColor};
`;

const ShowError = styled.div`
  padding: ${baseMargin};
  color: ${props => props.theme.redColor};
`;

const BackTop = styled.div`
  margin-top: ${baseMargin};
  text-align: center;
  text-decoration: underline;
`;

const WrapHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const OutlineWrapper = styled.div`
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

export default ReserveConfirmSpTemplate;
