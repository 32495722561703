import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import type OrderItem from '@this/src/domain/order_item';
import type TransportElement from '@this/src/domain/transport_element';

interface Props {
  orderItem: OrderItem;
  element: TransportElement;
}

const getIsShinkansen = (element: TransportElement) => {
  return element.isShinkansen() || element.isExpress() || element.isForeignRailway();
};

const getIsExic = (element: TransportElement, orderItem: OrderItem) => {
  if (element.railwayTicketType === 3) return 'all';
  if (orderItem.travelerInformations.length < 1) return false;
  if (orderItem.travelerInformations.some((ti: any) => ti.shinkansenTicketType === 'ex')) return 'part';
  return false;
};

const getTravelers = (orderItem: OrderItem, isExic: 'all' | 'part' | false) => {
  if (isExic === 'all') return orderItem.travelerInformations;
  if (isExic === 'part')
    return orderItem.travelerInformations.filter((ti: any) => ti.shinkansenTicketType === 'ex');
  return [];
};

export const UserExicCard: React.FC<Props> = observer(({ orderItem, element }) => {
  const [openIds, setOpenIds] = React.useState<number[]>([]);
  const isShinkansen = useMemo(() => getIsShinkansen(element), [element]);
  const isExic = useMemo(() => getIsExic(element, orderItem), [element, orderItem.travelerInformations]);
  const travelers = useMemo(() => getTravelers(orderItem, isExic), [isExic, orderItem.travelerInformations]);

  const handleToggleOpen = useCallback(
    (id: number) => {
      setOpenIds(openIds => {
        if (openIds.includes(id)) {
          return openIds.filter(openId => openId !== id);
        }
        return [...openIds, id];
      });
    },
    [setOpenIds]
  );

  const renderExicInfo = (ti: any) => {
    const usageMode = ti?.user?.organization?.exicUsageMode;

    if (usageMode === 'card_based') {
      const exicNumber = ti?.user?.exicCard?.exicNumber;
      const exicPassword = ti?.user?.exicCard?.password;
      return (
        <>
          <p>EX-IC番号：{exicNumber || '未設定'}</p>
          {renderPassword(exicPassword, ti.id)}
        </>
      );
    }

    if (usageMode === 'user_based' || !ti?.user) {
      const exicId = ti?.user?.exicId;
      const exicPassword = ti?.user?.exicPassword;
      return (
        <>
          <p>EX-IC ID：{exicId || '未設定'}</p>
          {renderPassword(exicPassword, ti.id)}
        </>
      );
    }
    return null;
  };

  const renderPassword = (password: string | undefined, id: number) => {
    if (password) {
      return (
        <p>
          EX-ICパスワード：
          {openIds.includes(id) ? password : password.replace(/./g, '*')}
          <IconButton size="small" onClick={() => handleToggleOpen(id)}>
            {openIds.includes(id) ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </p>
      );
    }
    return <p>EX-ICパスワード：未設定</p>;
  };

  if (!isShinkansen || !isExic || travelers.length < 1) return null;

  return (
    <div>
      ■ EX-IC（ユーザーアカウント情報）
      <br />
      {travelers.map((ti: any) => (
        <div key={ti.id}>
          {ti.lastNameKana} {ti.firstNameKana}：
          <br />
          <div style={{ marginLeft: '10px' }}>{renderExicInfo(ti)}</div>
        </div>
      ))}
    </div>
  );
});
