import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { styled } from '@this/constants/themes';
import Context from '@this/components/core_app/core_app_context';
import OrganizationBases from '@this/components/organization/organization_base/organization_bases';
import ExicCards from '@this/components/organization/exic_cards/exic_cards';
import OrganizationInformations from '@this/components/organization/organization_information/organization_informations';
import type { OrganizationArgs } from '@this/domain/organization/organization2';
import OrganizationData from '@this/domain/organization/organization2';
import CsvBulkUpsertMember from '@this/components/organization/csv_bulk_action/csv_bulk_upsert_member';
import CsvBulkDeleteMember from '@this/components/organization/csv_bulk_action/csv_bulk_delete_member';
import type { OrganizationRoleResponse } from '@this/domain/organization_role/organization_role';
import OrganizationRole from '@this/domain/organization_role/organization_role';
import Trips from '@this/components/organization/trips/trips';
import SmartHrImportActions from '@this/components/organization/smart_hr/smart_hr_import_actions';
import SmartHrImportDataCrews from '@this/components/organization/smart_hr/smart_hr_import_data_crews';
import Dashboard from './dashboard/dashboard';
import TripReports from './trip_reports/trip_reports';
import Allowances from './allowances/allowances';
import Members from './members/members';
import Projects from './projects/projects';
import Departments from './departments/departments';
import ApproveItems from './approve_items/approve_items';
import ApproveItemNew from './approve_items/approve_item_new';
import ApproveItemEdit from './approve_items/approve_item_edit';
import ApproveItemLists from './approve_items/approve_item_lists/approve_item_lists';
import ApproveItemListsBulkUpsert from './approve_items/approve_item_lists/csv_bulk_upsert/csv_bulk_upsert';
import ApproveItemListCsvImportActions from './approve_items/approve_item_lists/csv_bulk_upsert/approve_item_list_csv_import_actions';
import ApproveItemListCsvImportActionDetail from './approve_items/approve_item_lists/csv_bulk_upsert/approve_item_list_csv_import_action_detail';
import BulkActionStatus from './bulk_action_status/bulk_action_status';
import CsvBulkCreateProjects from './csv_bulk_action/csv_bulk_create_projects';
import BulkActionStatuses from './bulk_action_statuses/bulk_action_statuses';
import CsvBulkUpsertDepartments from './csv_bulk_action/csv_bulk_upsert_departments';
import CsvBulkUpdateApprovers from './csv_bulk_action/csv_bulk_update_approvers';
import CsvBulkUpdateProjectApprovers from './csv_bulk_action/csv_bulk_update_project_approvers';
import CsvBulkUpsertEXicCards from './csv_bulk_action/csv_bulk_upsert_exic_cards';
import ImportItems from './import_items/import_items';
import Invoices from './invoices/invoices';
import InvoiceCustomFormats from './invoice_custom_formats/invoice_custom_formats';
import InvoiceCustomFormatNew from './invoice_custom_formats/invoice_custom_format_new';
import InvoiceCustomFormatEdit from './invoice_custom_formats/invoice_custom_format_edit';
import CreditCard from './credit_card/credit_card';
import Analysis from './analysis/analysis';
import Setting from './setting/setting';
import Payment from './payment/payment';
import Roles from './organization_roles/roles';
import ExicReports from './exic_reports/exic_reports';
import ElementTickets from './element_tickets/element_tickets';
import ExpensesAccountTypes from './expenses_account_types/expenses_account_types';
import ExpensesTypes from './expenses_types/expenses_types';
import InAdvanceMessageTemplates from './in_advance_message_templates/in_advance_message_templates';
import Sidenav from './sidenav';
import MarketLogs from './market_logs/market_logs';

export const OrganizationPaths = [
  '/organization',
  '/organization/trips',
  '/organization/trip_reports',
  '/organization/exic_reports',
  '/organization/members',
  '/organization/departments',
  '/organization/departments/csv/bulk_upsert',
  '/organization/approve_items',
  '/organization/projects',
  '/organization/organization_bases',
  '/organization/bulk_action_statuses',
  '/organization/bulk_action_statuses/:id',
  '/organization/import_items',
  '/organization/invoices',
  '/organization/invoice_custom_formats',
  '/organization/credit_card',
  '/organization/element_tickets',
  '/organization/setting',
  '/organization/payment',
  '/organization/member/csv/bulk_upsert',
  '/organization/member/csv/bulk_delete',
  '/organization/metrics',
  '/organization/allowances',
  '/organization/expenses_account_types',
  '/organization/expenses_types',
  '/organization/in_advance_message_templates',
  '/organization/market_logs'
];

interface Props {
  serviceId: number;
  availableOptions: string[];
}

interface State {
  roleLoading: boolean;
  organizationLoading: boolean;
  organization: OrganizationData | undefined;
  useExicExpence: boolean | undefined;
  allowedPaths: string[];
  dailyAllowanceAvailable: boolean;
  approveItemAvailable: boolean;
  tripReportAvailable: boolean;
  marketLogAvailable: boolean;
  showExicSeisanTrips: boolean;
}

interface Response {
  organization: OrganizationArgs;
  allowedPaths: string[];
}

class Organization extends React.Component<Props, State> {
  static contextType = Context;

  context!: React.ContextType<typeof Context>;

  constructor(props: Props) {
    super(props);
    this.state = {
      roleLoading: true,
      organizationLoading: true,
      organization: undefined,
      useExicExpence: false,
      allowedPaths: [],
      dailyAllowanceAvailable: props.availableOptions.includes('daily_allowance'),
      approveItemAvailable: props.availableOptions.includes('approve_item'),
      tripReportAvailable: props.availableOptions.includes('trip_report'),
      marketLogAvailable: props.availableOptions.includes('market_log'),
      showExicSeisanTrips: false
    };
  }

  componentDidMount() {
    this.fetchOrganization();
    this.fetchResources();
  }

  fetchOrganization() {
    utils
      .jsonPromise<Response>('/organization/organization.json')
      .then(
        response => {
          const organization = new OrganizationData(response.organization);
          this.setState({
            useExicExpence: organization.useExicExpence,
            showExicSeisanTrips: organization.showExicSeisanTrips,
            organizationLoading: false,
            organization
          });
        },
        () => {
          this.setState({
            useExicExpence: false
          });
        }
      )
      .catch(e => {
        this.setState({
          useExicExpence: false
        });
      });
  }

  fetchResources() {
    utils
      .jsonPromise<OrganizationRoleResponse>('/organization/role.json')
      .then(data => {
        const role = new OrganizationRole(data.roles[0]);
        const paths = role.resources.map(resource => {
          if (!resource.readPermission) {
            return '';
          }
          return resource.resourcePath;
        });
        this.setState({ allowedPaths: paths, roleLoading: false });
      })
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  pathMatch() {
    const allowedPaths = this.state.allowedPaths;
    if (allowedPaths.indexOf('/organization/members') >= 0) {
      allowedPaths.push('/organization/member/csv/bulk_upsert');
      allowedPaths.push('/organization/member/csv/bulk_delete/new');
      allowedPaths.push('/organization/member/smart_hr/import_actions');
    }
    if (allowedPaths.indexOf('/organization/projects') >= 0) {
      allowedPaths.push('/organization/projects/csv/bulk_create');
      allowedPaths.push('/organization/projects/csv/bulk_update_approvers');
    }
    if (allowedPaths.indexOf('/organization/departments') >= 0) {
      allowedPaths.push('/organization/departments/csv/bulk_upsert');
      allowedPaths.push('/organization/departments/csv/bulk_update_approvers');
    }
    if (allowedPaths.indexOf('/organization/exic_cards') >= 0) {
      allowedPaths.push('/organization/exic_cards/csv/bulk_upsert');
    }
    if (
      allowedPaths.indexOf('/organization/invoices') >= 0 &&
      location.pathname.indexOf('/organization/invoice_custom_formats') >= 0
    ) {
      return true;
    }
    if (
      allowedPaths.indexOf('/organization/approve_items') >= 0 &&
      location.pathname.indexOf('/organization/approve_items') >= 0
    ) {
      return true;
    }
    // 要検討：現状は無条件でファイルアップロードのステータス画面にアクセスはできるようにした。
    if (location.pathname.match(/bulk_action_statuses/)) {
      return true;
    }
    // 要検討：ファイルアップロードのステータス画面と同様の設定にしている
    if (location.pathname.match('/organization/member/smart_hr/import_actions/')) {
      return true;
    }

    return allowedPaths.indexOf(location.pathname) >= 0;
  }

  render() {
    try {
      const { serviceId, availableOptions } = this.props;
      const {
        roleLoading,
        organizationLoading,
        organization,
        useExicExpence,
        dailyAllowanceAvailable,
        approveItemAvailable,
        tripReportAvailable,
        marketLogAvailable,
        showExicSeisanTrips
      } = this.state;
      return (
        <Wrapper>
          <div className="organization__left">
            {organizationLoading || roleLoading ? (
              <SimpleLoading />
            ) : (
              <Sidenav
                tripReportAvailable={tripReportAvailable}
                dailyAllowanceAvailable={dailyAllowanceAvailable}
                useExicExpence={!!useExicExpence}
                approveItemAvailable={approveItemAvailable}
                enableBatchApproval={!!organization?.enableBatchApproval}
                marketLogAvailable={marketLogAvailable}
                creditCardPaymentSettingAvailable={!!organization?.creditCardPaymentSettingAvailable}
              />
            )}
          </div>
          <div className="organization__right">
            {organizationLoading || roleLoading ? (
              <SimpleLoading />
            ) : (
              <>
                {this.pathMatch() ? (
                  <Switch>
                    <Route exact path="/organization" component={Dashboard} />
                    <Route
                      exact
                      path="/organization/trips"
                      render={() => (
                        <Trips
                          serviceId={serviceId}
                          availableOptions={availableOptions}
                          organization={organization}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/organization/market_logs"
                      render={() => <MarketLogs serviceId={serviceId} />}
                    />
                    <Route
                      exact
                      path="/organization/trip_reports"
                      render={() => <TripReports serviceId={serviceId} />}
                    />
                    {dailyAllowanceAvailable && (
                      <Route exact path="/organization/allowances" component={Allowances} />
                    )}
                    {useExicExpence && <Route exact path="/organization/exic_reports" component={ExicReports} />}
                    <Route
                      exact
                      path="/organization/members"
                      render={props => <Members {...props} serviceId={serviceId} />}
                    />

                    <Route exact path="/organization/projects" component={Projects} />
                    <Route exact path="/organization/expenses_account_types" component={ExpensesAccountTypes} />
                    {tripReportAvailable && (
                      <Route exact path="/organization/expenses_types" component={ExpensesTypes} />
                    )}
                    {organization?.enableBatchApproval && (
                      <Route
                        exact
                        path="/organization/in_advance_message_templates"
                        component={InAdvanceMessageTemplates}
                      />
                    )}
                    <Route exact path="/organization/departments" component={Departments} />
                    <Route exact path="/organization/approve_items" component={ApproveItems} />
                    <Route
                      exact
                      path="/organization/approve_items/new"
                      render={props => <ApproveItemNew {...props} showExicSeisanTrips={showExicSeisanTrips} />}
                    />
                    <Route
                      path="/organization/approve_items/:id/edit"
                      render={props => <ApproveItemEdit {...props} showExicSeisanTrips={showExicSeisanTrips} />}
                    />
                    <Route
                      path="/organization/approve_items/:approveItemId/approve_item_lists/bulk_upsert"
                      component={ApproveItemListsBulkUpsert}
                    />
                    <Route
                      exact
                      path="/organization/approve_items/:approveItemId/approve_item_lists/approve_item_list_csv_import_actions"
                      component={ApproveItemListCsvImportActions}
                    />
                    <Route
                      path="/organization/approve_items/:approveItemId/approve_item_lists/approve_item_list_csv_import_actions/:id"
                      component={ApproveItemListCsvImportActionDetail}
                    />
                    <Route
                      path="/organization/approve_items/:approveItemId/approve_item_lists"
                      component={ApproveItemLists}
                    />
                    <Route
                      exact
                      path="/organization/organization_bases"
                      render={props => <OrganizationBases {...props} availableOptions={availableOptions} />}
                    />
                    <Route
                      exact
                      path="/organization/exic_cards"
                      render={props => <ExicCards {...props} availableOptions={availableOptions} />}
                    />
                    <Route exact path="/organization/projects/csv/bulk_create" component={CsvBulkCreateProjects} />
                    <Route
                      exact
                      path="/organization/projects/csv/bulk_update_approvers"
                      component={CsvBulkUpdateProjectApprovers}
                    />
                    <Route
                      exact
                      path="/organization/departments/csv/bulk_upsert"
                      component={CsvBulkUpsertDepartments}
                    />
                    <Route
                      exact
                      path="/organization/departments/csv/bulk_update_approvers"
                      component={CsvBulkUpdateApprovers}
                    />
                    <Route
                      exact
                      path="/organization/exic_cards/csv/bulk_upsert"
                      component={CsvBulkUpsertEXicCards}
                    />
                    <Route exact path="/organization/bulk_action_statuses" component={BulkActionStatuses} />
                    <Route exact path="/organization/bulk_action_statuses/:id" component={BulkActionStatus} />
                    <Route exact path="/organization/import_items" component={ImportItems} />
                    <Route exact path="/organization/invoices" component={Invoices} />
                    <Route exact path="/organization/invoice_custom_formats" component={InvoiceCustomFormats} />
                    <Route
                      exact
                      path="/organization/invoice_custom_formats/new"
                      component={InvoiceCustomFormatNew}
                    />
                    <Route
                      exact
                      path="/organization/invoice_custom_formats/:id/edit"
                      component={InvoiceCustomFormatEdit}
                    />
                    <Route exact path="/organization/credit_card" component={CreditCard} />
                    <Route exact path="/organization/element_tickets" component={ElementTickets} />
                    <Route
                      exact
                      path="/organization/setting"
                      render={props => <Setting {...props} {...this.props} />}
                    />
                    {organization?.creditCardPaymentSettingAvailable && (
                      <Route
                        exact
                        path="/organization/payment"
                        render={props => <Payment {...props} {...this.props} />}
                      />
                    )}
                    <Route exact path="/organization/roles" component={Roles} />
                    <Route exact path="/organization/informations" component={OrganizationInformations} />
                    <Route
                      exact
                      path="/organization/member/csv/bulk_upsert"
                      render={props => <CsvBulkUpsertMember {...props} organization={organization} />}
                    />
                    <Route
                      exact
                      path="/organization/member/csv/bulk_delete/new"
                      render={() => <CsvBulkDeleteMember />}
                    />
                    <Route
                      exact
                      path="/organization/member/smart_hr/import_actions"
                      render={props => <SmartHrImportActions />}
                    />
                    <Route
                      exact
                      path="/organization/member/smart_hr/import_actions/:id"
                      render={() => <SmartHrImportDataCrews />}
                    />
                    <Route exact path="/organization/metrics" component={Analysis} />
                  </Switch>
                ) : (
                  <AccessDenied>
                    このページに対するアクセス権限がありません。管理者の方にご確認ください。
                  </AccessDenied>
                )}
              </>
            )}
          </div>
        </Wrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrapper = styled.div`
  display: flex;
  font-size: 13px;
  flex-grow: 9999;
  width: 100%;
  max-width: 100%;
  min-width: 1200px;
  background: ${props => props.theme.wrapperBgColor};
`;

const AccessDenied = styled.div`
  padding: 8px 10px;
`;

export default Organization;
