import { Fetcher, HTTPError } from '@this/src/util';
import { UPSERT_FORMAT } from './exic_card_file_formats';
import { DownloadType, Encoding } from './types';
import CsvBulkAction from './csv_bulk_action';

export default class CsvBulkUpsertExicCards extends CsvBulkAction {
  dataEnabled = true;

  historyPath = '/organization/bulk_action_statuses?actionType=upsert_exic_cards';

  backPath = '/organization/exic_cards';

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      formats: UPSERT_FORMAT,
      title: 'EX-IC一括登録・更新',
      description: [
        'CSVファイルをアップロードすることで、EX-ICカード情報を一括登録・更新することができます。',
        '既存の情報を編集したい場合はプルダウンメニューより「登録データ」を選択の上、ダウンロードして編集してください。',
        '※ 存在しているEX-IC番号がCSVに含まれている場合、「EX-IC番号、 パスワード」は上書きされます。'
      ].join('\n')
    };
  }

  downloadFormat = (type: DownloadType) => {
    if (type === DownloadType.DATA_UTF8 || type === DownloadType.DATA_SJIS) {
      const encoding = type === DownloadType.DATA_UTF8 ? Encoding.UTF8 : Encoding.SJIS;
      location.href = `/organization/exic_cards/csv/bulk_upsert/template_with_exic_cards?encoding=${encoding}`;
    } else {
      location.href = `/organization/exic_cards/csv/bulk_upsert/template?encoding=${type}`;
    }
  };

  upload = async (file: File) => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append('file', file);
    try {
      await Fetcher.upload('/organization/exic_cards/csv/bulk_upsert', formData);
      this.setState({ uploadResult: { uploaded: true } });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.error) {
        this.setState({ uploadResult: { uploaded: false, errors: [e.response.data.error] } });
      } else {
        this.setState({
          uploadResult: { uploaded: false, errors: ['通信エラーが発生しました。時間をおいて再度お試しください。'] }
        });
      }
    }
    this.setState({ loading: false });
  };
}
