/* eslint-disable max-lines */
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import type { PaymentGatewayType, PaymentMethodType } from '@this/domain/organization/organization2';
import MarginTypeList from '@this/domain/organization/margin_type_list';
import Department from '@this/domain/department/department';
import OrganizationBase from '@this/domain/organization_base/organization_base';
import ChargingDepartmentShareList from '@this/domain/department/charging_department_share_list';
import ProjectList from '@this/domain/project/project_list';
import ProjectShareList from '@this/domain/project/project_share_list';
import ApproverList from '@this/domain/approver/approver_list';
import type UserJson from '@this/domain/user/user_json';
import User from '@this/domain/user/user';
import TravelerList from '@this/domain/traveler/traveler_list';
import Traveler from '@this/domain/traveler/traveler';
import type {
  RentalCarJson,
  ConsignmentSale,
  PaymentGatewayMember,
  PaymentTransaction
} from '@this/domain/reserve_info';
import ReserveInfo from '@this/domain/reserve_info';
import ReservingTrip from '@this/domain/trip/reserving_trip';
import ReservingTripItem from '@this/domain/trip/reserving_trip_item';
import type { PackageProvider } from '@this/domain/select_store';
import type { RentalCarLimitTypeJson } from '@this/domain/organization/rental_car_limit_type';
import RentalCarLimitType from '@this/domain/organization/rental_car_limit_type';
import Trip from '@this/domain/trip/trip';
import type { OrderItemMappingArgs } from '@this/domain/order_item_mapping';
import Prefecture from '@this/domain/prefecture';
import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import ExpensesAccountType from '@this/domain/expenses/expenses_account_type';
import TripRuleError from '@this/domain/trip/trip_rule_error';
import type { SearchType } from '@this/domain/search_query';

import type { WorkflowStyle } from '@this/domain/workflow_style';
import TripRuleDistanceTimeInfo from '@this/domain/trip/trip_rule_distance_time_info';
import { Fetcher, HTTPError, trackClick, trackEvent, trackView } from '@this/src/util';
import ExicCard from '@this/domain/exic_card';
import ReserveConfirmPcTemplate from './reserve_confirm_pc.template';
import ReserveConfirmSpTemplate from './reserve_confirm_sp.template';

interface ShowResponse {
  user: UserJson;
  approvers: never;
  queries: { peoplenum: number; roomnum: number; stay_days: number; search_type: SearchType };
  search_query_id: never;
  type: never;
  airline: never;
  package_type: never;
  package_provider?: PackageProvider;
  package_search_condition: never;
  foreign_exist: never;
  domestic_exist: never;
  shinkansen_address: never;
  shinkansen_addressee: never;
  shinkansen_address_type: never;
  shinkansen_fee?: never;
  domestic_air_fee: never;
  foreign_air_fee: never;
  use_bulk_ticket: boolean;
  use_kyuusyuu_ticket: boolean;
  show_ex: boolean;
  has_members: boolean;
  organization_bases: never[];
  departments: never[];
  exic_cards: ExicCard[];
  charging_department_shares: never;
  postcode: never;
  payment_gateway_type: PaymentGatewayType | undefined;
  payment_gateway_members: PaymentGatewayMember[] | undefined;
  payment_transaction: PaymentTransaction | undefined;
  sony_payment_gateway_token_generate_script_url: string;
  car_type: [string, string][];
  rentalcar_available: boolean;
  rental_cars: RentalCarJson[];
  is_smoke: [string, string][];
  projects: never;
  project_shares: never;
  order_item_mapping_args: OrderItemMappingArgs[];
  outword: never;
  homeword: never;
  hotel?: { bed_types: { id: number; description: string }[] };
  flight: never;
  items: {
    element_id: string;
    element_raw: never;
    element_type: 'transport' | 'hotel' | 'flight';
    foreign_exist: string; // "false" で返ってくる;
    changeable_air: string; // "true" で返ってくる;
    index: string; // "0" で返ってくる
    domestic_air_price_index: number;
  }[];
  margin_types: never;
  show_fee: never;
  draft_trip: { purpose: never } | null;
  select_page_url: string;
  all_travelers_notification: never;
  notified_users: UserJson[];
  token_host: string;
  shop_id: string;
  travelers: any;
  traveler_candidates: UserJson[];
  consignment_sale: ConsignmentSale;
  login_user_id: number;
  project_share_availability: boolean;
  prefecture: Prefecture[];
  rental_car_limit_type: RentalCarLimitTypeJson;
  nationalities: { id: number; name: string }[];
  trip_rule_errors: TripRuleError[];
  expenses_account_type_available: boolean;
  expenses_account_types: ExpensesAccountTypeJson[];
  use_default_workflow: boolean;
  workflow_style: WorkflowStyle;
  trip_duplications: TripDuplicationInterface[];
  trip_distance_and_time_info: TripRuleDistanceTimeInfo[];
}

export interface TripDuplicationInterface {
  trip_id: number;
  user_name: string;
  element_types: ('hotel' | 'shinkansen' | 'express' | 'domestic_air' | 'foreign_air' | 'rental_car')[];
}

interface PaymentResponse {
  firstTrip: boolean;
  trip_id: never;
}

interface GmoTokenResult {
  tokenObject: {
    token: string;
    toBeExpiredAt: string;
    maskedCardNo: string;
    isSecurityCodeSet: boolean;
  };
  resultCode: string;
}

interface Props extends RouteComponentProps {
  // 画面表示用User
  user: User;
  serviceId: number;
}

type State = {
  reserveInfo: ReserveInfo | null;
  queries: { peoplenum: number; stay_days: number; search_type: SearchType } | null;
  loading: boolean;
  returning: boolean;
  submitting: boolean;
  errors: never[];
  showError: boolean;
  itemsBlankError: boolean;
  showHotelDetailModal: boolean;
  shinkansenFee: number | undefined;
  selectedIndex: number;
  peoplenum: number;
  domesticAirFee: number;
  foreignAirFee: number;
  showSelectTravelers: boolean;
  paymentLoading: boolean;
  paymentFailed: boolean;
  paymentErrors: string;
  steps: string[];
  stepIndex: number;
  termsAgree: boolean;

  // 旅程作成用User
  user?: UserJson;
  roomnum: number | undefined;
  useBulkTicket: boolean | undefined;
  useKyuusyuuTicket: boolean | undefined;
  showEx: boolean | undefined;
  approvers?: ApproverList;
  departments: Department[] | undefined;
  organizationBases: OrganizationBase[] | undefined;
  exicCards: ExicCard[];
  selectPageUrl: string | undefined;
  responseError: string | undefined;
  shopId: string;
  projectShareAvailability: boolean;
  prefecture: Prefecture[];
  tripRuleErrors: TripRuleError[];
  tripDuplications: TripDuplicationInterface[];
  tripDistanceAndTimeInfo: TripRuleDistanceTimeInfo[];
  canPayment: boolean;
  rejectMessage: string;
};

class ReserveConfirm extends React.Component<Props, State> {
  static handleToken(result: GmoTokenResult) {
    return $('#token').trigger('onChange', result);
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      reserveInfo: null, // new ReserveInfo(),
      queries: null,
      loading: true,
      returning: false,
      submitting: false,
      errors: [],
      showError: false,
      itemsBlankError: false,
      showHotelDetailModal: false,
      shinkansenFee: 0,
      selectedIndex: 0,
      peoplenum: 0,
      domesticAirFee: 0,
      foreignAirFee: 0,
      showSelectTravelers: false,
      paymentLoading: false,
      paymentFailed: false,
      paymentErrors: '',
      steps: ['Step 1. 入力', 'Step 2. 確認', 'Step 3. 完了'],
      stepIndex: 0,
      termsAgree: false,

      roomnum: undefined,
      useBulkTicket: undefined,
      useKyuusyuuTicket: undefined,
      showEx: undefined,
      departments: undefined,
      organizationBases: undefined,
      exicCards: [],
      selectPageUrl: undefined,
      responseError: undefined,
      shopId: '',
      projectShareAvailability: false,
      prefecture: [],
      tripRuleErrors: [],
      tripDistanceAndTimeInfo: [],
      tripDuplications: [],
      canPayment: true,
      rejectMessage: ''
    };
  }

  componentDidMount() {
    const url = new URL(location.href);
    const searchQueryId = url.searchParams.get('search_query_id') ?? '';

    if (location.hash && location.hash.length > 0) {
      location.href = `/reserve_confirm?search_query_id=${searchQueryId}`;
    }
    Fetcher.get<ShowResponse>(`/reserve_confirm/show.json?search_query_id=${searchQueryId}&${Date.now()}`)
      .then(async result => {
        if (result.payment_gateway_type === 'gmo') {
          utils.appendScript(`https://${result.token_host}/ext/js/token.js`);
          if (this.refs.token) {
            $(this.refs.token).bind('onChange', this.handleTokenChange);
          }
        }

        const approvers = _.sortBy(result.approvers, 'approve_stage');
        if (!_.isUndefined(result.user.department)) {
          result.user.department.approvers = approvers;
        }
        const user = new User(result.user);
        const travelers = ReserveConfirm.getInitialTravelers(result, user);

        const marginTypes = new MarginTypeList(result.margin_types);
        const rentalCarLimitType = new RentalCarLimitType(result.rental_car_limit_type);
        const reservingTrip = new ReservingTrip({
          packageType: result.type,
          airline: result.airline,
          peopleNum: result.queries.peoplenum,
          rentalCars: result.rental_cars,
          showFee: result.show_fee,
          marginTypes,
          items: _.map(
            result.items,
            item => new ReservingTripItem(_.merge(item, { showFee: result.show_fee, marginTypes }))
          ),
          rentalCarLimitType
        });
        travelers.list.map(t => t.setReservingTrip(reservingTrip));

        // ここでpayment_method_typeを上書きする
        let canPayment = true;
        let rejectMessage = '';
        let paymentMethodType = result.user.organization.payment_method_type !== 'card' ? 'billing' : 'card';
        const paymentGatewayType = result.payment_gateway_type;
        let paymentMethodOptions = result.user.organization.payment_method_type;
        if (paymentMethodType === 'card' && paymentGatewayType === 'sony') {
          let containForeign = false;
          let containDomestic = false;
          reservingTrip.items.some(item => {
            if (item?.foreignExist) {
              containForeign = true;
            } else {
              containDomestic = true;
            }

            if (containForeign && containDomestic) {
              canPayment = false;
              rejectMessage = 'この旅程は手配依頼できません。';
              return true;
            }
            return false;
          });

          if (containForeign && !containDomestic) {
            paymentMethodType = 'billing';
            paymentMethodOptions = 'invoice';
          }
        }
        const reserveInfo = new ReserveInfo({
          reservingTrip,
          roomNum: result.queries.roomnum,
          type: result.type,
          packageType: result.package_type,
          packageProvider: result.package_provider || null,
          packageSearchCondition: result.package_search_condition,
          travelers,
          user,
          foreignExist: result.foreign_exist,
          domesticExist: result.domestic_exist,
          shinkansenAddress: result.shinkansen_address,
          shinkansenAddressee: result.shinkansen_addressee,
          shinkansenAddressType: result.shinkansen_address_type,
          postcode: result.postcode,
          paymentGatewayType: result.payment_gateway_type,
          paymentGatewayMembers: result.payment_gateway_members,
          carType: result.car_type,
          rentalCarAvailable: result.rentalcar_available,
          rentalCars: result.rental_cars,
          paymentMethodOptions,
          paymentMethodType: paymentMethodType as PaymentMethodType,
          paymentTransaction: {
            uid:
              result.user.organization.payment_method_type === 'card' &&
              result.payment_gateway_members &&
              result.payment_gateway_members.length > 0
                ? result.payment_gateway_members[0].uid
                : 'new'
          },

          departments: result.departments.map(d => new Department(d)),
          chargingDepartmentShares: new ChargingDepartmentShareList(result.charging_department_shares),
          projects: new ProjectList(result.projects),
          projectShares: new ProjectShareList(result.project_shares),
          expensesAccountTypes: result.expenses_account_types.map(e => new ExpensesAccountType(e)),
          orderItemMappingArgsList: result.order_item_mapping_args,
          searchQueryId: result.search_query_id,
          purpose: ReserveConfirm.getInitialPurpose(result),
          allTravelersNotification: result.all_travelers_notification,
          notifiedUsers: result.notified_users.map(user => new Traveler(user)),
          hasMembers: result.has_members,
          consignmentSale: result.consignment_sale,
          loginUserId: result.login_user_id,
          rentalCarLimitType,
          nationalities: result.nationalities,
          expensesAccountTypeAvailable: result.expenses_account_type_available,
          useDefaultWorkflow: result.use_default_workflow,
          workflowStyle: result.workflow_style
        });
        await reserveInfo.fetchApproveItems('before_business_trip');
        this.setState({
          reserveInfo,
          queries: result.queries,
          user: result.user,
          shinkansenFee: result.shinkansen_fee,
          loading: false,
          peoplenum: Number(result.queries.peoplenum),
          roomnum: result.queries.roomnum,
          domesticAirFee: result.domestic_air_fee,
          foreignAirFee: result.foreign_air_fee,
          useBulkTicket: result.use_bulk_ticket,
          useKyuusyuuTicket: result.use_kyuusyuu_ticket,
          showEx: result.show_ex,
          approvers: new ApproverList(approvers),
          departments: result.departments.map(d => new Department(d)),
          organizationBases: result.organization_bases.map(o => new OrganizationBase(o)),
          exicCards: result?.exic_cards?.map(o => new ExicCard(o)) || [],
          selectPageUrl: result.select_page_url,
          shopId: result.shop_id,
          projectShareAvailability: result.project_share_availability,
          prefecture: result.prefecture.map(r => new Prefecture(r)),
          tripRuleErrors: result.trip_rule_errors.map(tripRuleError => new TripRuleError(tripRuleError)),
          tripDistanceAndTimeInfo: result.trip_distance_and_time_info.map(
            tripRuleDistanceTimeInfo => new TripRuleDistanceTimeInfo(tripRuleDistanceTimeInfo)
          ),
          tripDuplications: result.trip_duplications,
          canPayment,
          rejectMessage
        });

        if (reserveInfo.paymentGatewayType === 'sony') {
          const scriptUrl = result.sony_payment_gateway_token_generate_script_url;

          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = scriptUrl;
          script.setAttribute('callBackFunc', 'setSonyPaymentToken');
          script.className = 'spsvToken';
          document.head.appendChild(script);

          window.setSonyPaymentToken = this.setSonyPaymentToken;
        }
        trackView('/reserve_confirm#1');
      })
      .then(
        () => {},
        error => {
          if (error instanceof HTTPError && error.response?.status === 400) {
            const message = error.response.data.error;
            if (message) {
              if (message === 'items_blank') {
                this.setState({
                  itemsBlankError: true,
                  loading: false
                });
              } else {
                this.setState({
                  showError: true,
                  loading: false,
                  responseError: message
                });
              }
            } else {
              this.setState({
                returning: true,
                loading: false
              });
            }
          } else {
            this.setState({
              showError: true,
              loading: false,
              responseError: error.response.data.error
            });
          }
        }
      );
  }

  componentWillUnmount() {
    if (this.state.reserveInfo?.paymentGatewayType === 'sony') {
      window.setSonyPaymentToken = null;
    }
  }

  setSonyPaymentToken = (token: string, maskedCard: string) => {
    this.state.reserveInfo!.setPaymentTransaction({ token, uid: 'new' });
    this.requestCardSeq();
  };

  isCustomerInfoRequired = () => !!this.state.user && !!this.state.user.organization;

  handlePaymentSucceed(result: PaymentResponse) {
    const queryFirst = result.firstTrip ? '?first=true' : '';
    const url = `/trips/${result.trip_id}${queryFirst}`;
    location.href = url;
  }

  // openSelectTravelers(index: never, e: Event) {
  //   e.preventDefault();
  //   this.setState({
  //     selectedIndex: index,
  //     showSelectTravelers: true
  //   });
  // }

  // closeSelectTravelers(e: Event) {
  //   e.preventDefault();
  //   this.setState({ showSelectTravelers: false });
  // }

  facebookAdConversion() {
    fbq('track', 'Purchase', {
      value: this.state.reserveInfo && this.state.reserveInfo.reservingTrip.totalPrice(),
      currency: 'JPY'
    });
  }

  handleBackToSelectClick = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.props.history.push(this.state.selectPageUrl || '/dashboard');
    }, 100);
  };

  handleBackToStep1Click = () => {
    this.setState({ stepIndex: 0 });
  };

  handleValidationSuccess = () => {
    this.setState({ stepIndex: 1 });
    return utils.scrollToTop();
  };

  handlePaymentSubmit = () => {
    trackClick('Payment submit');
    this.setState({
      paymentLoading: true
    });

    if (!this.state.reserveInfo) return undefined;
    if (!this.state.canPayment) {
      this.setState({
        paymentLoading: false,
        paymentFailed: true,
        paymentErrors: 'こちらの旅程は手配依頼できません。'
      });
      utils.sendErrorObject(new Error('こちらの旅程は手配依頼できません。'));
    }
    if (
      this.state.reserveInfo.paymentMethodType === 'card' &&
      this.state.reserveInfo.paymentGatewayType === 'gmo'
    ) {
      if (this.refs.token) {
        $(this.refs.token).unbind('onChange');
        $(this.refs.token).bind('onChange', this.handleTokenChange);
      }

      if (this.state.reserveInfo.paymentTransaction?.uid === 'new') {
        if (typeof Multipayment !== 'undefined') {
          Multipayment.init(this.state.shopId);
          Multipayment.getToken(this.state.reserveInfo.getNewCardParams(), ReserveConfirm.handleToken);
        } else {
          this.setState({
            paymentLoading: false,
            paymentFailed: true,
            paymentErrors: ReserveConfirm.approvalErrorMessage()
          });
          utils.sendErrorObject(new Error('Multipayment does not exist'));
        }

        return undefined;
      }
    }

    if (
      this.state.reserveInfo.paymentMethodType === 'card' &&
      this.state.reserveInfo.paymentGatewayType === 'sony'
    ) {
      if (this.state.reserveInfo.paymentTransaction?.uid === 'new') {
        const cardParams = this.state.reserveInfo.getNewCardParams();
        SpsvApi.spsvCreateToken(
          cardParams.cardno,
          cardParams.expireYear,
          cardParams.expireMonth,
          cardParams.securitycode,
          '',
          '',
          '',
          '',
          ''
        );
      } else {
        return this.requestCardSeq();
      }
    }

    if (this.state.reserveInfo.paymentMethodType === 'billing') {
      return Fetcher.post<PaymentResponse>('/payments/billing.json', this.state.reserveInfo.submitParams()).then(
        result => {
          trackEvent('Billing succeed');
          this.facebookAdConversion();
          this.handlePaymentSucceed(result);
        },
        error => {
          let errors;
          trackEvent('Billing failed');
          if (error instanceof HTTPError && error.response?.data?.errors) {
            errors = error.response?.data?.errors;
          } else {
            errors = ReserveConfirm.approvalErrorMessage();
          }
          this.setState({
            paymentLoading: false,
            paymentFailed: true,
            paymentErrors: errors
          });
        }
      );
    }
    if (
      this.state.reserveInfo.paymentMethodType === 'card' &&
      this.state.reserveInfo.paymentGatewayType === 'gmo'
    ) {
      return this.requestCardSeq();
    }

    return true;
  };

  requestCardSeq = () => {
    const params = _.merge(this.state.reserveInfo!.submitParams(), {
      payment_transaction: this.state.reserveInfo?.paymentTransaction
    });

    Fetcher.post<PaymentResponse>('/payments/card_seq.json', params).then(
      result => {
        trackEvent('Payment succeed');
        this.facebookAdConversion();
        this.handlePaymentSucceed(result);
      },
      error => {
        let errors;
        trackEvent('Payment failed');
        if (error instanceof HTTPError && error.response?.data?.errors) {
          errors = error.response?.data?.errors;
        } else {
          errors = ReserveConfirm.approvalErrorMessage();
        }
        this.setState({
          paymentLoading: false,
          paymentFailed: true,
          paymentErrors: errors
        });
      }
    );
  };

  // preタグを使うので文字列内のスペースがそのまま出る
  static approvalErrorMessage() {
    return `申し訳ございません。ご予約を受け付けることができませんでした。

時間を空けてもう一度お試しいただき、改善されない場合は
[お問い合わせフォーム](/inquiries/new)よりご連絡ください。`;
  }

  handleTokenChange = (e: unknown, result: GmoTokenResult) => {
    if (result.resultCode === '000') {
      this.setState({ paymentFailed: false });
      const params = _.merge(this.state.reserveInfo && this.state.reserveInfo.submitParams(), {
        token: result.tokenObject.token
      });
      Fetcher.post<PaymentResponse>('/payments/token.json', params).then(
        result => {
          trackEvent('Payment succeed');
          this.handlePaymentSucceed(result);
        },
        error => {
          let errors;
          trackEvent('Payment failed');
          if (error instanceof HTTPError && error.response?.data?.errors) {
            errors = error.response?.data?.errors;
          } else {
            errors = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          }
          this.setState({
            paymentLoading: false,
            paymentFailed: true,
            paymentErrors: errors
          });
        }
      );
    } else if (result.resultCode === '901') {
      this.setState({
        paymentLoading: false,
        paymentFailed: true,
        paymentErrors: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
      });
    } else {
      this.setState({
        paymentLoading: false,
        paymentFailed: true,
        paymentErrors: '決済に失敗しました。\n入力内容に誤りがないかご確認ください。'
      });
      this.setState({ paymentFailed: false });
    }
  };

  handleAgreementTermsCheck = () => {
    this.setState({
      termsAgree: !this.state.termsAgree
    });
  };

  private static getInitialTravelers(result: ShowResponse, user: User) {
    if (result.draft_trip) {
      const trip = new Trip(result.draft_trip);
      return TravelerList.fromTravelerInformations({
        informations: trip.travelerInformations,
        user,
        members: [],
        peoplenum: result.queries.peoplenum
      });
    }

    if (result.travelers.length > 0) {
      const members = _.map(result.traveler_candidates, u => new User(u));
      return TravelerList.fromQuery({
        travelerQueries: result.travelers,
        user,
        members,
        peoplenum: result.queries.peoplenum
      });
    }

    const travelers = TravelerList.fromCount(result.queries.peoplenum);
    travelers.setTravelerAtIndex(0, new Traveler(user));
    if (result.queries.peoplenum > 1) {
      for (
        let i = 1, end = result.queries.peoplenum - 1, asc = end >= 1;
        asc ? i <= end : i >= end;
        asc ? (i += 1) : (i -= 1)
      ) {
        if (user.show_other_members && result.has_members) {
          travelers.setTravelerTypeAtIndex(i, 'member');
        } else {
          travelers.setTravelerTypeAtIndex(i, 'companion');
        }
      }
    }
    return travelers;
  }

  private static getInitialPurpose(result: ShowResponse) {
    return (result.draft_trip && result.draft_trip.purpose) || '';
  }

  render() {
    try {
      return (
        <Confirm>
          {/* onChangeは第2引数とらないのでhandleTokenChangeの型が使えない */}
          <input id="token" type="hidden" onChange={this.handleTokenChange as any} ref="token" />
          <ConfirmPc>
            <ReserveConfirmPcTemplate
              {...this.props}
              {...this.state}
              isCustomerInfoRequired={this.isCustomerInfoRequired}
              handleValidationSuccess={this.handleValidationSuccess}
              handleBackToSelectClick={this.handleBackToSelectClick}
              handleBackToStep1Click={this.handleBackToStep1Click}
              handlePaymentSubmit={this.handlePaymentSubmit}
              handleAgreementTermsCheck={this.handleAgreementTermsCheck}
              userInfo={this.props.user}
            />
          </ConfirmPc>
          <ConfirmSp>
            <ReserveConfirmSpTemplate
              {...this.props}
              {...this.state}
              isCustomerInfoRequired={this.isCustomerInfoRequired}
              handleValidationSuccess={this.handleValidationSuccess}
              handleBackToSelectClick={this.handleBackToSelectClick}
              handleBackToStep1Click={this.handleBackToStep1Click}
              handlePaymentSubmit={this.handlePaymentSubmit}
              handleAgreementTermsCheck={this.handleAgreementTermsCheck}
              userInfo={this.props.user}
            />
          </ConfirmSp>
        </Confirm>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const baseColor = '#af985e';
const blackColor = '#292929';
const grayColor = '#eee';

const Confirm = styled.div`
  min-height: 100vh;
`;

const ConfirmPc = styled.div`
  display: block;

  min-height: 100vh;

  @media (max-width: 1000px) {
    display: none;
  }

  > :first-child {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
  }

  header {
    background: ${blackColor};
    border-bottom: 1px solid ${baseColor};
  }

  .header-in {
    max-width: 100%;
    background: none;
    border-bottom: none;
    padding: 0 20px;
  }

  .logo {
    padding-left: 0;
  }

  .trips-button {
    margin-right: 0;
  }

  .in-advance-button {
    margin-right: 10px;
  }

  .info-content {
    position: relative;
    background: white;

    &__title {
      &__link {
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0;
      }
    }
  }
`;

const ConfirmSp = styled.div`
  display: none;

  min-height: 100vh;

  @media (max-width: 1000px) {
    display: block;
  }

  > :first-child {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
  }

  .header-in {
    max-width: 100%;
  }

  .content-wrapper {
    flex-grow: 9999;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${grayColor};
  }

  .content-body {
    max-width: 100%;
    padding: 10px;
  }
`;

export default ReserveConfirm;
